* {
	@apply antialiased;
}

html {
	scroll-behavior: smooth;
}

body {
	@apply font-avenir text-black tracking-wide;
}

div {
	@apply relative;
}

.html--noScroll {
	overflow: hidden;
}