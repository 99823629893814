/* purgecss start ignore */
/* (PurgeCSS-ignored because this markup is summoned from third-party widget scripts.) */

.hsForm_prayerTeamSignup form {

	@apply flex flex-wrap -mx-3 mb-1;

	label {
		@apply block uppercase tracking-wide text-darkGray text-xs font-bold mb-2;
	}

	label.hs-error-msg {
		@apply text-red;
	}

	input[type=text],
	input[type=email] {

		@apply appearance-none block w-full bg-gray text-darkGray border border-gray rounded py-3 px-4 mb-2 leading-tight;

		&:focus {
			@apply outline-none bg-white border-darkGray;
		}

	}

	input[type=submit] {

		@apply shadow bg-darkBlue text-white font-bold my-4 py-2 px-4 rounded;

		&:focus {
			@apply ring outline-none;
		}

		&:hover {
			@apply bg-medBlue;
		}

	}

	div.hs_email {

		@apply w-full px-3 mb-3;

		@screen md {
			@apply mb-2;
		}

	}

	div.hs_firstname, div.hs_lastname {

		@apply w-full px-3 mb-3;

		@screen md {
			@apply w-1/2 mb-2;
		}

	}

	div.hs_submit {

		@apply w-full px-3;

	}

}

.cv_genform_content div.interLabelAboveInput span.ccnumbertxt {
	top:0 !important;
	left:3px !important;
}
/* purgecss end ignore */
