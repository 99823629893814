/* purgecss start ignore */
.grid-row {
    margin: 0; }
.grid-row svg {
    display: inline;
}
.grid-row .grid-half-row {
    height: 300px; }
@media only screen and (max-width: 767px) {
    .grid-row .grid-half-row {
        height: auto; } }
.grid-row .grid-full-row {
    height: 600px;
    /*width:100%;*/ }
@media only screen and (max-width: 767px) {
    .grid-row .grid-full-row {
        height: auto; } }
@media only screen and (max-width: 540px) {
    .grid-row .grid-col {
        height: 720px; } }
.grid-row .grid-col,
.grid-row .row {
    padding: 0;
    margin: 0;
    overflow: hidden; }
@media only screen and (max-width: 767px) {
    .grid-row .grid-col,
    .grid-row .row {
        overflow: visible;
        height: auto; } }
.grid-row .overlay, .grid-row .dark-blue-overlay, .grid-row .dark-blue-overlay-less {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.grid-row .logo-icon {
    color: white;
    margin-top: 70px;}
.grid-row .logo-icon svg {
    width:300px;
    height:150px;
}
@media only screen and (max-width:1024px) {
    .grid-row .logo-icon {
        margin-top: 20px;
        margin-bottom: 0px; } }
@media only screen and (max-width:1280px) {
    .grid-row .logo-today,
    .grid-row .logo-thinkchristian {
        margin-top: 20px;
        margin-bottom: 0px; } }
.grid-row .logo-icon svg {
    fill: white;
    max-width: 70%;
}

.grid-row .logo-kidscorner svg {
    max-width: 70%; }
@media only screen and (max-width: 767px) {
    .grid-row .logo-today svg {
        max-width: 240px; } }
@media only screen and (max-width: 767px) {
    .grid-row .logo-thinkchristian svg {
        max-width: 200px; } }
.grid-icon-reverse {
    color: #083b5c;
    margin-top: 0; }
.grid-row .grid-icon-reverse.logo-kidsCorner svg {
    fill: #8e4e9e;}
.grid-row .grid-icon-reverse.logo-familyFire svg {
    fill: #24488b; }
.grid-row .grid-icon-reverse.logo-groundwork svg {
    fill: #84ba64; }
.grid-row .grid-icon-reverse.logo-churchJuice svg {
    fill: #fbb040; }
.grid-row .grid-item {
    z-index: 10;
    padding: 0;
    margin: 0;
    text-align: center; }
.grid-row .grid-item a {
    text-decoration: none;
    display: block;
    transition: transform 0.4s ease-in-out; }
.grid-row .grid-item a:hover {
    /*opacity: 0.6;*/
    transform: scale(1.1);
    }
.grid-row .grid-item .grid-arrow {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    z-index: 20;
    position: absolute;
    top: 94px; }
.grid-row .grid-item .grid-arrow.grid-arrow-right {
    border-left: 50px solid white;
    right: -50px; }
.grid-row .grid-item .grid-arrow.grid-arrow-left {
    border-right: 50px solid white;
    left: -50px; }
.grid-row .grid-text {
    padding: 30px; }
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .grid-row .grid-text {
        padding: 25px; } }
.grid-row .grid-text h2 {
    font-size: 40px;
    font-size: 2.5rem; }
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .grid-row .grid-text h2 {
        font-size: 30px;
        font-size: 1.875rem; } }
.grid-row .grid-text h2 a {
    text-decoration: none; }
.grid-row .grid-text p {
    font-size: 16px;
    font-size: 1rem;
    margin: 2px 0; }
.grid-row .grid-center {
    display: table-cell !important;
    vertical-align: middle;
    height: 300px; }
.grid-row .grid-full-row p {
    margin: 1.4em 0; }
.grid-row .grid-text-reverse P {
    color: white;
    font-size: 20px;
    font-size: 1.25rem; }
/* purgecss end ignore */