@font-face {
    font-family: 'AvenirLight';
    src: local('Avenir LT Std Light'), local('Avenir Light'),
    url('../fonts/AvenirLTStd-Light.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Light Oblique'), local('Avenir Light Oblique'),
    url('../fonts/AvenirLTStd-LightOblique.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'AvenirBook';
    src: local('Avenir LT Std Book'), local('Avenir Book'),
    url('../fonts/AvenirLTStd-Book.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Book Oblique'), local('Avenir Book Oblique'),
    url('../fonts/AvenirLTStd-BookOblique.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Roman'), local('Avenir Roman'),
    url('../fonts/AvenirLTStd-Roman.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Oblique'), local('Avenir Oblique'),
    url('../fonts/AvenirLTStd-Oblique.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Medium'), local('Avenir Medium'),
    url('../fonts/AvenirLTStd-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Medium Oblique'), local('Avenir Medium Oblique'),
    url('../fonts/AvenirLTStd-MediumOblique.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Heavy'), local('Avenir Heavy'),
    url('../fonts/AvenirLTStd-Heavy.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Heavy Oblique'), local('Avenir Heavy Oblique'),
    url('../fonts/AvenirLTStd-HeavyOblique.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Black'), local('Avenir Black'),
    url('../fonts/AvenirLTStd-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir LT Std Black Oblique'), local('Avenir Oblique'),
    url('../fonts/AvenirLTStd-BlackOblique.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


/*
Sentinel
 */

@font-face {
    font-family: 'Sentinel';
    src: local('Sentinel Book'),
    url('../fonts/Sentinel-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sentinel';
    src: local('Sentinel Medium'),
    url('../fonts/Sentinel-Medium.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sentinel';
    src: local('Sentinel Bold'),
    url('../fonts/Sentinel-Bold.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}