/* purgecss start ignore */
.library_searchbarContainer {
    margin-bottom: 0.875rem;
}

.searchbar {
    position: relative; }
.searchbar > .searchbar_input {
    display: block;
    width: 100%;
    padding: 0.875rem 0.875rem 0.875rem 3.5rem;
    border: 3px solid #f1f3f8;
    font-size: 18px;
    font-size: 1.125rem; }
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .searchbar > .searchbar_input {
        padding-left: 0.875rem;
        font-size: 18px;
        font-size: 1.125rem; } }
.searchbar > .searchbar_input:active, .searchbar > .searchbar_input:focus {
    outline: none; }
.searchbar > .searchbar_icon {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 23px;
    height: 100%;
    width: 1.75rem;
    color: #a9d5dc; }
.searchbar > .searchbar_icon > svg {
    height: 100%;
    width: 1.75rem; }
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .searchbar > .searchbar_icon {
        display: none; } }


.library_results mark {
    background-color: transparent;
    background-image: linear-gradient(-180deg, transparent, rgba(180, 210, 48, 0.7) 35%, transparent); }

.library_results .articleImageFrame {
    padding-bottom: 0;
    margin-bottom: 1em; }

.library_results h4 {
    font-size: 22px;
    font-size: 1.375rem;
    padding: 0 !important;
    margin: 0 0 .75rem 0; }

.library_results .articlePreviewCard_meta {
    font-size: 16px;
    font-size: 1rem; }

.library_results .articlePreviewCard_postType {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 800; }
.library_results .articlePreviewCard_postType.__devotions {
    color: #132e39; }

.library_results .articlePreviewCard_ministry {
    margin:0;
    color:white;
    display: block;
    margin-bottom:.5rem;
}
.library_results .articlePreviewCard_ministry span {
    padding:0.5rem .75rem;
}
.library_results .articlePreviewCard_ministry .Arabic {
    background-color:#956d73;
}
.library_results .articlePreviewCard_ministry .Chinese {
    background-color:#002f71;
}
.library_results .articlePreviewCard_ministry .English {
    background-color:#a4d587;
}
.library_results .articlePreviewCard_ministry .French {
    background-color:#a7524a;
}
.library_results .articlePreviewCard_ministry .Hindi {
    background-color:#ffa323;
}
.library_results .articlePreviewCard_ministry .Indonesian {
    background-color:#6ea340;
}
.library_results .articlePreviewCard_ministry .Japanese {
    background-color:#bb3c4c;
}
.library_results .articlePreviewCard_ministry .Portuguese {
    background-color:#6a6f9d;
}
.library_results .articlePreviewCard_ministry .Russian {
    background-color:#00586e;
}
.library_results .articlePreviewCard_ministry .Spanish {
    background-color:#72cbb8;
}



.library_controls {
    background-color: #f1f3f8;
    margin-right: 0.875rem;
    padding: 0.875rem 0.875rem 0.875rem; }

.library_controlsHeading {
    margin: 0 0 0.875rem;
    padding: 0 0 4px;
    /*@include altTitleFont;*/
    font-size: 15.3px;
    font-size: 0.95625rem;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2); }

.library_controlsList {
    margin: 0 0 2rem;
    list-style: none;
    line-height: 1.2;
    font-size: 14px;
    font-size: 0.875rem; }
.library_controlsList li {
    margin: 0.6125rem 0; }
.library_controlsList li.selected > .library_facet {
    font-weight: 900;
    color: #a056b8; }
.library_controlsList li ul {
    margin: 0 0 0 0.875rem; }
.library_controlsList input[type=checkbox] {
    display: none; }
.library_controlsList + button {
    display: block;
    margin-top: -0.4375rem;
    margin-bottom: 0.875rem; }


.library_facet {
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
.library_facet > .count {
    display: inline-block;
    justify-self: end;
    margin-left: auto;
    padding-left: 0.3125rem; }
.library_facet span.title {
    text-transform: capitalize; }
.library_facet:hover > .title {
    text-decoration: underline; }

.library_searchResultSnippet {
    margin: 0 0 0.875rem;
    padding: 0;
    font-size: 16px;
    font-size: 1rem;
}


.library_pagebar {
    background-color: #f7f7f7;
    padding: 1rem 2rem;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1; }

.library_pagebar_pageLink {
    padding: .5rem;
    text-decoration: none; }
@media only screen and (max-width: 601px) {
    .library_pagebar_pageLink {
        padding: 0.29167rem; } }
.library_pagebar_pageLink.selected {
    text-decoration: underline;
    font-weight: 900; }
button.library_pagebar_pageLink:hover {
    text-decoration: underline; }
span.library_pagebar_pageLink {
    cursor: default; }

.library_alert {
    background-color: #a5db65;
    padding: 0.875rem;
    font-size: 14px;
    font-size: 0.875rem; }

.library_info {
    background-color: #f1f3f8;
    padding: 1rem;
    font-size: 14px;
    font-size: 0.875rem; }
.library_info button {
    display: inline-block;
    padding: .625rem;
    background-color: rgba(0, 0, 0, 0.075);
    color: currentColor;
    font-weight: 900;
    line-height: 1;
    text-decoration: none;
    border-radius: 1px;
    letter-spacing: 0;
    text-transform: capitalize; }

.library_error {
    background-color: #f44949;
    padding: 0.875rem;
    font-size: 14px;
    font-size: 0.875rem;
    color: white; }

.searchActionBtn {
    color: #873e9f;
    text-decoration: underline; }
.searchActionBtn:hover {
    text-decoration: none;
    opacity: 0.8; }
/* purgecss end ignore */