/* purgecss start ignore */
.tabs--container .tabs__header--container .tab__header:first-child {
    @apply rounded-t-none;
}
.tabs--container .tabs__header--container .tab__header a.tab--active {
    @apply bg-white;
    @apply relative;
    @apply top-px;
    @apply font-black;
    @apply no-underline;
    @apply border-r-0;
}

.tabs--container .tabs__content--container .tab {
    @apply hidden;
}
.tabs--container .tabs__content--container .tab.tab--active {
    @apply block;
}


.tabs--container.tabs--vertical {
    @apply w-full;
}
.tabs--container.tabs--vertical .tabs__header--container {
    width: 28%;
    @apply float-left;
}
.tabs--container.tabs--vertical .tabs__header--container .tab__header {
    @apply m-0;
    @apply p-0;
    @apply text-right;
    @apply rounded-none;
    @apply w-full;
}
.tabs--container.tabs--vertical .tabs__header--container .tab__header:last-child a.tab--active {
    @apply border-medGray;
    @apply border-b;
}


.tabs--container.tabs--vertical .tabs__content--container {
    @apply float-right;
    width: 70%;
}
.tabs__content--container h3 {
    @apply text-2xl;
    @apply text-darkBlue;
    @apply font-black;
    @apply font-avenir;
    @apply pt-4;
}
/* purgecss end ignore */