/* purgecss start ignore */
.modalWindow {
    @apply w-full;
    @apply h-full;
    @apply fixed;
    @apply top-0;
    @apply left-0;
    z-index: 50;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.7);
}

.modalContent {
    @apply w-full;
    @apply absolute;
    @apply top-2/4;
    @apply left-2/4;
    @apply bg-white;
    @apply p-8;
    z-index: 60;
    max-width:960px;
    transform: translate(-50%, -50%);
}

.modalClose {
    @apply absolute;
    top: -50px;
    right: -28px;
    z-index: 1001;
    width: 70px;
    @apply no-underline;
    @apply cursor-pointer;
}
.modalClose svg {
    fill:white;
    width:40px;
    height:40px;
}

/* purgecss end ignore */