#pageContents {
	@apply pt-16;
	@apply pb-32;
	@apply font-light;
	@apply text-xl;

	p {
		@apply leading-relaxed;
	}
	 p + p {
		@apply my-8;
	}
	 ul {
		@apply my-8;
		@apply list-disc;
	}
	 ol {
	 	@apply mt-4;
		@apply list-decimal;
	}
	li {
		@apply ml-8;
		@apply mb-5;
	}
	li.tab__header {
		@apply m-0;
	}
	img {
		@apply max-w-full;
		@apply align-middle;
	}
	a {
		@apply underline;
	}
	a:hover {
		@apply no-underline;
	}
	.mp-learn-more-links a,
	.ministry-button a,
	.tab__header a
	a.nounderline,
	a.button {
		@apply no-underline;
	}

	h3 + p,
	h4 + p,
	h5 + p,
	h6 + p {
		@apply mt-7;
	}
	h3 + p.meta,
	h4 + p.meta,
	h5 + p.meta,
	h6 + p.meta {
		@apply mt-1;
	}

	strong {
		@apply font-avenirheavy;
		@apply font-bold;
	}
	em {
		@apply italic;
	}

	.bodyText h2,
	.bodyText h3,
	.bodyText h4,
	.bodyText h5,
	.bodyText h6 {
		@apply font-sent;
		@apply my-8;
		@apply text-darkBlue;
		@apply font-black;
		@apply relative;
	}
	.bodyText h2 { @apply text-5xl; }
	.bodyText h3 { @apply text-4xl; }
	.bodyText h4 { @apply text-3xl; }
	.bodyText h5 { @apply text-2xl; }
	.bodyText h6 { @apply text-xl; }
}
