.row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.boxShadow { box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4); }
.boxShadowLight { box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2); }
.h-500 { height:500px; }
.h-600 { height:600px; }
.h-800 { height:800px; }
.min-h-80 { min-height:80px; }
.min-h-300 { min-height:300px; }
.min-h-400 { min-height:400px; }
.min-h-480 { min-height:480px; }
.min-h-600 { min-height:600px; }

.mainNav li ul.subNav { transition: all 0s ease-in-out .2s; }
.mainNav > li > a { transition: all 0s ease-in-out .1s; }
.mainNav > li.topNav:hover > ul.subNav { left: 0; }
.mainNav > li.topNav.megamenu:hover > div.subNav { left: -100%; }


.mastheadLogo > svg {
    @apply w-56;
}

#mobile-nav-button > svg {
    fill:white;
}
.transparentNav #mobile-nav-button > svg {
    fill:#083b5c;
    @apply relative;
    top:.9rem;
}
.searchNav > svg {
    fill:#fff;
    @apply w-8;
    @apply h-8;
    @apply ml-4;
    @apply inline;
}
.noMastehead .searchNav > svg {
    fill:#083b5c;
}
.pageNavigation > svg {
    @apply w-4;
    @apply h-4;
    @apply inline;
}
.storyLogo > svg {
    @apply w-32;
    @apply h-32;
    @apply mx-auto;
}
.footerLogo > svg {
    @apply w-72;
    @apply mx-auto;
}
.footer-social-icon > svg {
    fill: white;
    @apply w-10;
    @apply h-10;
}
.crcna-logo > svg {
    stroke:white;
    @apply w-16;
    @apply mx-auto;
}
.footer-guideStar > svg {
    @apply w-20;
    @apply h-20;
}
#scrollup > svg {
    @apply w-9;
    @apply h-9;
    stroke:white;
}
.close-circle > svg {
    @apply w-9;
    @apply h-9;
    fill:white;
    @apply mx-auto;
}
svg.svgDown {
    fill: none;
    stroke: #083b5c;
    stroke-width: 13;
    stroke-miterlimit: 10;
    width: 120px;
    @apply mx-auto;
    @apply my-12;
}
.todayLogo__home > svg {
    fill: white;
    width: 400px;
    height: 200px;
    @apply mx-auto;
}
.downloadLink {
    transition: transform 0.3s ease-in-out;
}
.downloadLink:hover {
    transform: translate(20px, 0px);
}
.downloadLink > svg {
    @apply inline;
    fill: white;
    height:20px;
    width:14px;
    @apply relative;
    top:-2px;
    margin-left:0.25em;
}
.holiday-icon {
    @apply mt-9;
    @apply mb-6;
}
.holiday-icon > svg {
    height:60px;
    @apply mx-auto;
    @apply max-w-full;
}
/* purgecss start ignore */
.c-churchJuice > svg {
    fill:#fbb040;
}
.c-familyFire > svg {
    fill:#24488b;
}
.c-groundwork > svg {
    fill:#84ba64;
}
.c-kidsCorner > svg {
    fill:#8e4e9e;
}
.c-thinkChristian > svg {
    fill:#b24525;
}
.c-today > svg {
    fill:#2c5664;
}
.ministryCircle > svg {
    width:16px;
    height:16px;
}
.button > svg {
    fill:white;
    width:20px;
    height: 20px;
    margin-right: 5px;
    display: inline;
    position: relative;
    top: -2px;
}

.connectIcons > svg {
    width:28px;
    height:28px;
    fill:#083b5c;
    margin:0 5px;
    display: inline;
    position: relative;
}
/* purgecss end ignore */

.radio-item {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    @apply overflow-hidden;
    @apply p-0;
    @apply absolute;
    @apply w-px;
}

.radio-item ~ label {
    @apply w-full;
    @apply block;
    @apply relative;
}
.radio-item ~ label:before {
    @apply block;
    @apply w-full;
    @apply mt-4;
    @apply mb-0;
    @apply mx-auto;
    @apply relative;
    @apply cursor-pointer;
}
.darkForm .radio-item ~ label:before {
    content: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'><circle cx='18' cy='18' r='16' fill='none' stroke='white' stroke-width='4' /></svg>");
}
.darkForm .radio-item:checked ~ label:before {
    content: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'><circle cx='18' cy='18' r='16' fill='white' stroke='white' stroke-width='4' /></svg>");
}
.darkForm .radio-item ~ label {
    color: white;
}

.lightForm .radio-item ~ label:before {
    content: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'><circle cx='18' cy='18' r='16' fill='none' stroke='%23083b5c' stroke-width='4' /></svg>");
}
.lightForm .radio-item:checked ~ label:before {
    content: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'><circle cx='18' cy='18' r='16' fill='%23083b5c' stroke='%23083b5c' stroke-width='4' /></svg>");
}
.lightForm .radio-item ~ label {
    color: #083b5c;
}

.iso-grid-item a img {
    transition: transform 0.4s ease-in-out;
}
.iso-grid-item a:hover img {
    transform: scale(1.1);
}
.iso-grid-item .imageLoader {
    overflow:hidden;
}

.iso-grid-item {
    @apply w-full;
}
@screen sm {
    .mastheadLogo > svg  {
        @apply mt-2;
        @apply w-64;
    }
    .iso-grid-item {
        width:48%;
    }
}
@screen md {
    .iso-grid-item {
        width:32%;
    }
}

@screen lg {
    .mastheadLogo > svg  {
        @apply w-80;
    }
    .crcna-logo > svg {
        @apply mx-0;
    }
    .radio-item ~ label {
        display: inline-block;
    }
    .radio-item ~ label:before {
        display: inline-block;
        margin-right:1em;
        top:14px;
        width:1.4em;
    }
}

.grid_bgOverlay {
    background: linear-gradient(to top, #000 20%, transparent 100%);
    background-position: -20%;
    opacity: 0.5;
    transition: opacity .2s ease-in-out;
}

/* purgecss start ignore */
.videoContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 -> 9/16x100 */
    overflow: hidden;
}
.videoContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
/* purgecss end ignore */


/* Underline From Center */
.hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}
.hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
}
.btn-red:before {
    background: #960000;
}
.btn-darkBlue:before {
    background: #57c0e9;
}
.btn-lightBlue:before {
    background: #0364a0;
}
.btn-white:before {
    background: #57c0e9;
}

.hoverGrow {
    transition: transform 0.4s ease-in-out; }
.hoverGrow:hover {
    transform: scale(1.1);
}

.buttonShadow { box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.75); }

#pageContents {

    ul.pagination {

        li {
            @apply m-0;
            @apply list-none;

            a, span {
                @apply block;
                @apply no-underline;
                @apply px-3;
                @apply py-2;
                @apply leading-tight;
                @apply text-gray;
                @apply bg-white;
                @apply border;
                @apply border-medGray;
                @apply hover:bg-lightGray;
                @apply hover:text-darkGray;
            }

            a.pageNumber, span.pageNumber {
                padding-top: .26em;
                padding-bottom: .29em;
            }

            span {
                @apply text-darkBlue;
                @apply border-medBlue;
                @apply bg-lighterBlue;
            }
        }
    }

}