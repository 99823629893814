/* purgecss start ignore */
.fui-hidden {
    @apply hidden;
}
.fui-field {
    @apply flex-1;
}
.fui-row {
    @apply flex;
    @apply flex-wrap;
}


.fui-submit,
.fui-btn-wrapper,
.fui-btn-container {
    @apply flex;
    @apply pt-4;
    @apply -mx-2;
    @apply justify-start;
}
.fui-btn-wrapper .fui-btn,
.fui-btn-container .fui-btn {
    @apply mx-4;
    @apply uppercase;
    @apply rounded;
    @apply font-sent;
    @apply font-normal;
    @apply text-base;
    @apply py-4;
    @apply px-8;
    @apply cursor-pointer;
}
.fui-btn-wrapper .fui-btn:hover,
.fui-btn-container .fui-btn:hover {
    @apply opacity-60;
}
.fui-btn-wrapper .fui-submit,
.fui-btn-container .fui-submit {
    @apply bg-darkBlue;
    @apply text-white;
    @apply order-10;
}
.fui-btn-wrapper .fui-submit:hover,
.fui-btn-container .fui-submit:hover {
    @apply bg-darkBlue;
}



.fui-field {
    @apply px-2;
}

.fui-label-left-input .fui-input-container {
    @apply ml-2;
}

.fui-label-right-input .fui-input-container {
    @apply mr-2;
}



.fui-label,
.fui-legend {
    @apply font-sent;
    @apply text-darkGray;
    @apply text-lg;
    @apply leading-tight;
    @apply font-medium;
    @apply block;
    @apply pt-6;
    @apply mb-2;
}

.fui-error & {
    @apply text-red;
    @apply border-medGray;
}

.fui-required {
    @apply text-red;
}



.fui-fieldset {
    @apply m-0;
    @apply p-0;
    @apply border-none;
}



.fui-errors {
    @apply m-0;
    @apply p-0;
}

.fui-error-message {
    @apply text-red;
    @apply text-sm;
    @apply block;
    @apply mt-2;
}

.fui-alert {
    @apply p-4;
    @apply rounded;
    @apply text-sm;
    @apply font-medium;
    @apply mb-4;
}

.fui-alert-error {
    @apply bg-lightred;
    @apply text-red;
    @apply font-black;
}

.fui-alert-success {
    @apply bg-lighterBlue;
    @apply text-darkBlue;
}


.fui-input,
.fui-select {
    font-family: inherit;
    @apply text-sm;
    @apply leading-normal;
    @apply m-0;
    appearance: none;
    color-adjust: exact;

    @apply w-full;
    @apply block;
    @apply p-3;

    @apply rounded;
    background: #f1f3f8;
    box-shadow: inset 1px 1px 1px 0px #b3b4b7;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}

.fui-input:focus,
.fui-select:focus {
    outline: 0;
    border-color: #f1f3f8;
    box-shadow: 0 0 0 2px rgba(189, 189, 189, 0.45);
}

.fui-input.fui-error,
.fui-select.fui-error {
    @apply text-red;
    @apply border-medGray;
}

.fui-input.fui-error:focus,
.fui-select.fui-error:focus {
    box-shadow: 0 0 0 3px rgba(248, 180, 180, 0.45);
}

.fui-input::placeholder,
.fui-select::placeholder {
    @apply text-medGray;
}

/* purgecss end ignore */