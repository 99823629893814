imageLoader {
    @apply overflow-hidden;
    @apply relative;
    transform: translate3d(0, 0, 0);
}

.imageLoader img {
    @apply block;
    @apply w-full;
}

.imageLoader_image {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply opacity-0;
    transition: opacity 1s ease-in-out;
}

/* purgecss start ignore */
.imageLoader_image.lazyloaded {
    @apply opacity-100;
    @apply z-20;
}
/* purgecss end ignore */

.imageLoader_previewImage {
    @apply z-10;
    filter: blur(5px);
}

.imageBackgroundLoader {
    @apply w-full;
    @apply h-full;
    @apply bg-cover;
    @apply z-0;
}

.imageBackgroundLoader .imageBackgroundLoader_previewImage {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply h-full;
    @apply bg-cover;
    @apply bg-black;
    @apply z-0;
    filter: blur(5px);
    transition: opacity 1s ease-in-out;
}

/* purgecss start ignore */
.imageBackgroundLoader.lazyloaded .imageBackgroundLoader_previewImage {
    @apply opacity-0;
}
/* purgecss end ignore */

.blur-up {
    filter: blur(5px);
    transition: opacity 1s ease-in-out;
}

/* purgecss start ignore */
.blur-up.lazyloaded {
    filter: blur(0);
}
.no-blur .blur-up {
    filter: blur(0) !important;
}
.imageLoader.my-8 + .caption {
    @apply -mt-6
}
/* purgecss end ignore */